import React, { FC } from "react";
import { graphql } from "gatsby";
import { PressItemTemplateQuery } from "../../graphql-types";
import ReactResizeDetector from "react-resize-detector";
import { borders } from "../global-style";
import { WpHtmlContent } from "../components/wp-html-content";
import { TitleImageAndGrayBlock } from "../components/title-image-and-gray-block";

export const query = graphql`
  query PressItemTemplate($id: ID!) {
    wp {
      pressItem(id: $id) {
        title
        content
        featuredImage {
          
            sourceUrl
            localFile {
              childImageSharp {
                fluid(webpQuality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    
  }
`;

const PressItem: FC<{ data: PressItemTemplateQuery }> = props => {
  return (
    <ReactResizeDetector handleWidth={true}>
      {() => {
        return (
          <>
            <TitleImageAndGrayBlock
              title={props.data.wp.pressItem?.title}
              image={props.data.wp.pressItem?.featuredImage}
            />

            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                marginBottom: "12rem",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  flexBasis: "83rem",
                  paddingTop: "1.5rem",
                  border: borders.red,
                }}
              >
                <div
                  style={{
                    zIndex: 5,
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div style={{ textAlign: "center", marginTop: "12rem" }}>
                    <WpHtmlContent
                      htmlContent={props.data.wp.pressItem?.content || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </ReactResizeDetector>
  );
};

export default PressItem;
